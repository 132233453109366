<template>
    <div class="account_authhority">
        
        <div class="page-header">
            <el-row>
                <el-col :span="24" align="right">
                    <el-button type="primary" round icon="el-icon-s-home" @click="openDialog()">{{ $t('data.branch.addBranch') }}</el-button>
                </el-col>
            </el-row>
        </div>

        <el-row :gutter="20">
            <el-col :span="24">
                <el-card shadow="always">
                    <el-table
                    :data="tableData"
                    style="width: 100%">
                        <el-table-column
                            prop="id"
                            label="ID"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            :label="$t('data.branch.table.BranchName')">
                        </el-table-column>
                        <el-table-column
                            prop="address"
                            :label="$t('data.branch.table.BranchAddress')">
                        </el-table-column>
                        <el-table-column
                            prop="country.name"
                            :label="$t('data.branch.table.Country')">
                        </el-table-column>
                        <el-table-column
                            prop="contact_number"
                            :label="$t('data.branch.table.ContactNumber')">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            :label="$t('data.branch.table.ContactPerson')">
                        </el-table-column>
                        
                        <el-table-column :label="$t('data.public.action')" align="right" width="190">
                            <template #default="scope">
                                <el-button
                                size="mini"
                                @click="openDialog(scope.row)">{{ $t('data.public.edit') }}</el-button>
                                <el-popconfirm
                                confirm-button-type="danger"
                                @confirm="handleDelete(scope.row, scope.$index)"
                                :title="$t('data.branch.isDeleteText')+'？'">
                                    <template #reference>
                                        <el-button type="danger" size="small">{{ $t('data.public.delete') }}</el-button>
                                    </template>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>

                    <table-pagination :page="page" @updatePage="updatePage"></table-pagination>

                </el-card>
            </el-col>
        </el-row>

        <el-dialog title="Branch information" v-model="dialogFormVisible">
            <el-form :model="form">
                <el-form-item :label="$t('data.branch.table.BranchName')" :label-width="formLabelWidth">
                    <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label="$t('data.branch.table.BranchAddress')" :label-width="formLabelWidth">
                    <el-input v-model="form.address" autocomplete="off"></el-input>
                </el-form-item>
                
                <el-form-item :label="$t('data.branch.table.Country')" :label-width="formLabelWidth">
                    <el-select v-model="form.country_id" placeholder="Select" style="width:100%">
                        <el-option v-for="(item, index) in countryData" :key="index" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item :label="$t('data.branch.table.ContactNumber')" :label-width="formLabelWidth">
                    <div style="display: flex;">
                        <el-select v-model="form.country_id" placeholder="Select" style="width: 400px;">
                            <el-option v-for="(item, index) in countryData" :key="index" :label="item.area_code" :value="item.id"></el-option>
                        </el-select>
                        <el-input v-model="form.contact_number" autocomplete="off" style="margin-left: 15px;"></el-input>
                    </div>
                    
                </el-form-item>

                <el-form-item :label="$t('data.branch.table.eMail')" :label-width="formLabelWidth">
                    <el-input v-model="form.email" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">{{ $t('data.public.cancel') }}</el-button>
                <el-button type="primary" @click="submitForm()">{{ $t('data.public.save') }}</el-button>
                </span>
            </template>
        </el-dialog>

    </div>
</template>

<script>
import countryJson from '../../assets/js/country.json'
import tablePagination from "@/components/tablePagination/TablePagination"
import { ElMessage } from 'element-plus'
import { getCounties,branchesList,addBranches,editBranches,delBranches} from '../../api/index'
  export default {
      name: 'authority',
      components:{
        tablePagination
      },
      data() {
        return {
          page: {},
          countryData: {},
          tableData: [],
          form: {},
          dialogFormVisible: false,
          formLabelWidth: '140px'
        }
      },
      mounted(){
        this.getCountryData();
        this.initPageData();
      },
      methods: {
        initPageData(data){
          let apiData = {}
          if(data){
            Object.assign(apiData,data)
          }
          branchesList({apiData}).then( res => {
            this.tableData = res.data;
            this.page = res.meta.page;
          })

        },
        getCountryData(){
            getCounties({}).then( res => {
                this.countryData = res.data;
          })
        },
        openDialog(row) {
            if(row){
                this.form = row;
                this.form.country_id = row.country.id;
            } else {
                this.form = {
                    name: '',
                    email: '',
                    country_id: '',
                    area_code: '',
                    contact_number: '',
                    address: ''
                }
            }
            this.dialogFormVisible = true;
        },
        handleDelete(row, index) {
            let apiData = {id: row.id};
            delBranches({apiData}).then( res => {
                ElMessage.success({
                    message: 'Success',
                    type: 'success'
                });
                this.initPageData();
            })
        },
        submitForm(){
            if(this.form.country_id){
                this.form.area_code = this.countryData.filter(item => item.id === this.form.country_id)[0].area_code;
            }
            let apiData = this.form;
            if(this.form.id){
                editBranches({apiData}).then( res => {
                     ElMessage.success({
                        message: 'Success',
                        type: 'success'
                    });
                    this.dialogFormVisible = false;
                    this.initPageData();
                 })
            } else {
                addBranches({apiData}).then( res => {
                    ElMessage.success({
                        message: 'Success',
                        type: 'success'
                    });
                    this.dialogFormVisible = false;
                    this.initPageData();
                })
            }
            // this.dialogFormVisible = false;
        },
        updatePage(data){
            this.initPageData(data);
        }
      }
    }
</script>